<template>
  <div class="order-container">
    <div class="search-section">
      <div class="search-content">
        <search-input
            v-model="querys.code"
            :type.sync="queryType"
            :options="queryOptions"
            :placeholder="'请输入搜索内容，多个内容用逗号或换行符分隔'"
            @clear="clearQuery"
            @search="getData"
        />
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getData">
          {{ $t('domestic.search') }}
        </el-button>
        <el-badge :hidden="!badgeNum" :value="badgeNum" class="filter-badge">
          <el-button type="info" plain size="mini" icon="el-icon-s-operation" @click="showFilter">
            筛选
          </el-button>
        </el-badge>
        <el-button type="warning" plain size="mini" icon="el-icon-refresh" @click="resetForm">
          重置
        </el-button>
        <el-button v-if="OrderSource === 5" :disabled="!multipleSelection.length" type="danger" plain size="mini" icon="el-icon-delete" @click="cancelOrder({})">
          删除
        </el-button>
        <export-excel
            v-if="tableColumns && tableColumns.length"
            :selected-data="multipleSelection"
            :default-columns="tableColumns"
            :all-columns="allColumns"
            :storage-key="columnKey"
            :file-name-prefix="OrderSource === 4 ? `虾皮订单` : `CODd订单`"
        />
        <el-button
            v-if="OrderStatu===0"
            type="primary"
            plain
            size="mini"
            icon="el-icon-s-promotion"
            @click="handleBatchBookToBill"
            :disabled="!multipleSelection.length"
        >
          申请发货
        </el-button>
        <batch-print :template-name="OrderSource === 4 ? '虾皮拣货单' : '拣货单'" :multiple-selection="multipleSelection"></batch-print>
        <el-button type="danger" plain size="mini" icon="el-icon-setting" @click="colDrawer = true">列设置</el-button>
        <el-button
            v-if="OrderSource === 5"
            type="primary"
            plain
            size="mini"
            icon="el-icon-refresh"
            :loading="refreshLoading"
            :disabled="!multipleSelection.length"
            @click="getCarrierBillCode">获取承运单号</el-button>
        <div
            class="expand-button"
            :class="{ expanded: isSearchExpanded }"
            @click="isSearchExpanded = !isSearchExpanded"
        >
          <i :class="isSearchExpanded ? 'el-icon-minus' : 'el-icon-plus'"></i>
          {{ isSearchExpanded ? '收起搜索' : '展开搜索' }}
        </div>
      </div>
      <transition name="expand">
        <div v-show="isSearchExpanded" class="expanded-search">
          <advanced-search
              :query-form.sync="queryForm"
              @search="getData"
          />
        </div>
      </transition>
    </div>
    <div class="status-indicators" v-if="OrderStatu !== 3">
      <div class="status-item">
        <div class="status-dot warning-ball"></div>
        <span>缺货</span>
      </div>
      <div class="status-item">
        <div class="status-dot half-warning-ball"></div>
        <span>部分缺货</span>
      </div>
      <div class="status-item">
        <div class="status-dot success-ball"></div>
        <span>库存充足</span>
      </div>
      <div class="status-item">
        <div class="status-dot info-ball"></div>
        <span>未绑定库存</span>
      </div>
    </div>
    <el-table
        :data="tableData"
        border
        :key="`${columnKey}-${tableKey}-table`"
        ref="multipleTable"
        :height="tableHeight"
        :max-height="tableHeight"
        @selection-change="handleSelectionChange"
        :row-class-name="tableRowClassName"
        style="width: 100%"
        default-expand-all
        v-loading="loading"
        class="custom-table"
    >
      <el-table-column
          type="selection"
          width="55"></el-table-column>
      <el-table-column type="expand" class-name="expand-row">
        <template slot-scope="scope">
          <el-table
              :data="scope.row.OrderItemDetails"
              border
              :row-class-name="scope.row.IsCancel ? 'canceled-detail-row' : 'success-row'"
              :header-cell-class-name="scope.row.IsCancel ? 'canceled-header-row' : 'header-row'"
              style="width: 100%"
          >
            <el-table-column v-if="OrderSource===4" label="商品标题" prop="ItemName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="品名/规格" prop="GoodsName" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="照片" align="center" width="80px">
              <template slot-scope="slotScope">
                <el-image class="shopee-img" :preview-src-list="scope.row.OrderItemDetails.map(item => { return item.Img })" :src="slotScope.row.Img" v-if="slotScope.row.Img"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="SKU" prop="Sku" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="数量" prop="Amount" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="价格" prop="Price" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存扣除状态" show-overflow-tooltip align="center">
              <template slot-scope="slotScope">
                <span>{{ slotScope.row.DeductionTime ? '已扣除' : '未扣除' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="库存状态" prop="StockStateTxt" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column label="库存数量/冻结数量" show-overflow-tooltip align="center">
              <template slot-scope="slotScope">
                <span class="stock-link" @click="showStockQuantityDetail(slotScope.row.Id)">
                  {{ `${slotScope.row.StockQuantity}/${slotScope.row.FrozenQuantity}` }}
                  <i class="el-icon-view"></i>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="可出货数量" prop="ShippingQuantity" show-overflow-tooltip align="center"></el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
          v-for="col in tableColumns"
          show-overflow-tooltip
          :key="col.prop"
          sortable
          :prop="col.prop"
          :label="col.label"
          align="center"
          :width="col.width === 'auto' ? null : col.width"
      >
        <template slot-scope="scope">
          <template v-if="col.type === 'shopeeStatus'">
            <span>{{ orderStatusFormatter(scope.row, col , scope.row[col.prop]) }}</span>
          </template>

          <template v-else-if="col.type === 'status'">
            <div class="arrived-box" v-if="scope.row[col.prop]">{{ $t('order.paid') }}</div>
            <div class="unarrive-box" v-else>{{ $t('order.nonpayment') }}</div>
          </template>

          <template slot-scope="scope" v-else-if="col.type === 'inventoryStatus'">
            <div class="u-start-flex">
              <div v-if="OrderStatu!==3" class="mr8" :class="getClasses(scope.row.StockState)"></div>
              <div>{{ scope.row.MainBillCode }}</div>
            </div>
          </template>


          <template v-else-if="col.type === 'datetime'">
            <span>{{ scope.row[col.prop] | formatDate }}</span>
          </template>

          <template v-else-if="col.type === 'formatBool'">
            <span>{{ scope.row[col.prop] | formatType }}</span>
          </template>

          <template v-else>
            {{ scope.row[col.prop] }}
          </template>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          align="center"
          label="操作"
          width="120">
        <template slot-scope="scope">
          <div class="u-event-flex">
            <el-button
                v-if="false"
                type="text"
                size="small"
                @click="uploadVisible = true"
                title="面单上传"
            >
              <i class="el-icon-upload"></i>
            </el-button>
            <el-button
                v-if="!scope.row.IsCancel && scope.row.OrderStatu === 1"
                type="text"
                size="small"
                @click="outBound(scope.row.Id, scope.row.WareHouseId)"
                title="库存绑定"
            >
              <i class="el-icon-link"></i>
            </el-button>
            <el-button
                v-if="!scope.row.IsCancel && scope.row.OrderStatu === 1"
                type="text"
                size="small"
                @click="bookToBill(scope.row)"
                title="申请发货"
            >
              <i class="el-icon-s-promotion"></i>
            </el-button>
            <el-button
                v-if="scope.row.OrderSource === 5 && scope.row.OrderStatu === 1"
                type="text"
                size="small"
                @click="cancelOrder(scope.row)"
                title="取消订单"
            >
              <i class="el-icon-delete"></i>
            </el-button>
            <el-button
                v-if="!scope.row.IsCancel && scope.row.OrderStatu === 2"
                type="text"
                size="small"
                @click="cancelToBill(scope.row)"
                title="取消发货"
            >
              <i class="el-icon-close"></i>
            </el-button>
            <el-button
                v-if="!scope.row.IsCancel && scope.row.OrderStatu !== 1"
                type="text"
                size="small"
                @click="showOutBoundDetail(scope.row.Id)"
                title="发货查看"
            >
              <i class="el-icon-view"></i>
            </el-button>
          </div>
          <el-dropdown
              v-if="false"
              size="mini"
              trigger="click"
              @command="handleCommand($event, scope.row)"
          >
            <el-button type="text" size="small" title="更多操作">
              <i class="el-icon-more"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="order">
                <i class="el-icon-document"></i>
                拣货单
              </el-dropdown-item>
              <el-dropdown-item>
                <i class="el-icon-edit-outline"></i>
                修改收货地址
              </el-dropdown-item>
              <el-dropdown-item>
                <i class="el-icon-link"></i>
                绑定快递单号
              </el-dropdown-item>
              <el-dropdown-item>
                <i class="el-icon-scissors"></i>
                更改货物类型
              </el-dropdown-item>
              <el-dropdown-item command="detail">
                <i class="el-icon-search"></i>
                发货查看
              </el-dropdown-item>
              <el-dropdown-item command="unBind">
                <i class="el-icon-circle-close"></i>
                解绑库存
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="custom-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="PageSizes"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
    <filter-drawer :time-options="timeTypeOptions" :list-data="drawerListData" :initial-query="drawerQuery" @handle-confirm="handleConfirm" @close="handleDrawerClose" :drawer="drawer"></filter-drawer>
    <shopee-outbound :ware-house-id="WareHouseId" @close="handleVisibleClose" @refresh="handleRefresh" :order-id="currentStateId" :show="visible" :list-data.sync="outBoundData"></shopee-outbound>
    <out-bound-detail  @close="handleDetailClose" :order-id="currentStateId" :show="detailVisible" :list-data="outBoundData"></out-bound-detail>
    <stock-quantity-detail @close="handleStockQuantityClose" :item-id="currentItemId" :show="stockQuantityVisible"></stock-quantity-detail>
    <column-set-drawer ref="colSet" @close="handleColumnClose" @save="handleColumnSave" :show="colDrawer" :storage-key="columnKey" :default-columns="defaultColumns" :all-columns="allColumns"></column-set-drawer>
    <shopee-waybill-upload :show="uploadVisible" @close="handleUploadClose" :row="{}"></shopee-waybill-upload>
  </div>
</template>

<script>
import {
  deleteOrder,
  getOrderItemDetail,
  getOrderList,
  getSheetByte,
  getShopeeList,
  refreshCarrierBillCode,
  unBoundStock
} from "@/api/member";
import printJS from "print-js";
import ShopeeOutbound from "@/views/member/shopee/components/ShopeeOutbound.vue";
import outBoundDetail from "@/views/member/shopee/components/outBoundDetail.vue";
import StockQuantityDetail from "@/views/member/shopee/components/StockQuantityDetail.vue";
import {cancelOrderToDelivery, orderToDelivery} from "@/api/cod";
import batchPrint from "@/components/BatchPrint/index.vue";
import SearchInput from "@/components/SearchInput/index.vue";
import pagination from "@/utils/mixin/pagination";
import filterDrawers from "@/utils/mixin/filterDrawers";
import FilterDrawer from "@/components/FilterDrawer/index.vue";
import ColumnSet from "@/utils/mixin/columnSet";
import ColumnSetDrawer from "@/components/ColumnSetDrawer.vue";
import {getAllColumns, getDefaultColumns} from "@/utils/ShopeeCodColumns";
import ExportExcel from '@/components/ExportExcel/index.vue'
import ShopeeWaybillUpload from "@/components/ShopeeWaybillUpload/index.vue";
import AdvancedSearch from "@/components/AdvancedSearch/index.vue";
import AdvancedSearchMinix from "@/utils/mixin/AdvancedSearch"

export default {
  mixins: [pagination, filterDrawers, ColumnSet, AdvancedSearchMinix],
  components: {
    AdvancedSearch,
    ColumnSetDrawer, ShopeeWaybillUpload,
    FilterDrawer, SearchInput, ShopeeOutbound, outBoundDetail, StockQuantityDetail, batchPrint, ExportExcel },
  data() {
    return {
      queryCode: "",
      refreshLoading: false,
      uploadVisible: false,
      WareHouseId: 0,
      currentStateId: 0,
      currentItemId: 0,
      loading: false,
      detailVisible: false,
      stockQuantityVisible: false,
      visible: false,
      queryType: 3,
      queryOptions: [
        { label: '订单号', value: 3 },
        { label: '承运单号', value: 4 },
      ],
      outBoundData: [],
      tableData: [],
      multipleSelection: [],
      querys: {},
    }
  },

  props: {
    OrderSource: {
      type: Number,
      default: 0
    },
    OrderStatu: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.defaultColumns = getDefaultColumns(this.OrderSource)
    this.allColumns = getAllColumns()
    if (this.OrderSource === 4) {
      this.columnKey = "shopee"
    } else {
      this.columnKey = "cod"
    }
    setTimeout(() => {
      this.$refs.colSet.initColumns()
    }, 500)
    this.getData()
    this.initDrawerListData()
  },
  filters: {
    formatType(val) {
      if (val) {
        return "是"
      } else {
        return "否"
      }
    }
  },
  computed: {
    tableHeight() {
      if (this.OrderStatu === 3) {
        return 'calc(100vh - 15rem - 56px)'
      } else {
        return 'calc(100vh - 19.3rem - 56px)'
      }
    },
    timestampName() {
      const timestamp = Date.now()
      return `订单-${timestamp}.xls`
    },
  },
  methods: {
    async getCarrierBillCode() {
      if (!this.multipleSelection.length) {
        this.$message.warning('请选择需要获取承运单号的订单')
        return
      }

      this.refreshLoading = true
      try {
        // 创建所有请求的 Promise 数组
        const promises = this.multipleSelection.map(item => {
          return refreshCarrierBillCode({
            OrderId: item.Id
          })
        })

        // 等待所有请求完成
        await Promise.all(promises)

        this.$message.success('获取承运单号成功')
        // 刷新列表
        await this.getData()
      } catch (error) {
        this.$message.error('获取承运单号失败')
      } finally {
        this.refreshLoading = false
      }
    },
    handleUploadClose() {
      this.uploadVisible = false
    },
    cancelToBill(row) {
      this.$confirm(`是否取消订单编号为（${row.MainBillCode}）的订单发货，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          OrderId: row.Id
        }
        cancelOrderToDelivery(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success("取消成功")
            this.getData()
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
    initDrawerListData() {
      this.getShopeeList()
      if (this.OrderSource === 4) {
        this.$store.dispatch('webSite/getCarrier', 1).then(data => {
          this.drawerListData.push({
            title: "承运商",
            field: "carrier",
            options: data.map(item => {
              return {
                label: item.Name,
                value: item.Id
              }
            })
          })
        })
      } else {
        this.$store.dispatch('webSite/getCarrier').then(data => {
          this.drawerListData.push({
            title: "承运商",
            field: "carrier",
            options: data.map(item => {
              return {
                label: item.Name,
                value: item.Id
              }
            })
          })
        })
      }

      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.drawerListData.push({
          title: "仓库",
          field: "warehouse",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
      this.$store.dispatch('webSite/getCountry').then(data => {
        this.drawerListData.push({
          title: "目的地",
          field: "destination",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
      if (this.OrderStatu === 0) {
        this.drawerListData.push({
          title: "订单状态",
          field: "OrderStatu",
          options: [
            {
              label: "未发货",
              value: 1
            },
            {
              label: "待发货",
              value: 2
            },
            {
              label: "已发货",
              value: 3
            }
          ]
        })
      }
      this.drawerListData.push({
        title: "是否取消",
        field: "orderType",
        options: [
          {
            label: "未取消",
            value: 1
          },
          {
            label: "已取消",
            value: 2
          }
        ]
      })
      this.drawerListData.push({
        title: "库存状态",
        field: "StockState",
        options: [
          {
            label: "不限",
            value: 0
          },
          {
            label: "库存充足",
            value: 1
          },
          {
            label: "缺货",
            value: 2
          },
          {
            label: "部分缺货",
            value: 3
          },
          {
            label: "未绑定库存",
            value: 4
          }
        ]
      })
    },
    getShopeeList() {
      const data = {
        PageIndex: 1,
        PageRows: 99
      }
      getShopeeList(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.drawerListData.push({
            title: "店铺",
            field: "ElectronicShopId",
            options: Data.map(item => {
              return {
                label: item.ElectronicShopName,
                value: item.ElectronicShopId
              }
            })
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    cancelOrder(row) {
      let orderId = '';
      if (Object.keys(row).length) {
        orderId = row.Id;
      } else {
        orderId = this.multipleSelection.map(item => item.Id).join('、');
      }
      this.$confirm(
          `是否删除订单ID为${orderId}的数据`,
          "提示",
          {
            confirmButtonText: this.$t('order.deleteOrder.confirmText'),
            cancelButtonText: this.$t('order.deleteOrder.cancelText'),
            type: 'warning'
          }
      )
          .then(() => {
            let data = Object.keys(row).length ? [row.Id] : this.multipleSelection.map(item => item.Id);
            data = {
              Ids: data,
              IsRefund: true
            }
            deleteOrder(data)
                .then(response => {
                  if (response.Success) {
                    this.$message({
                      type: 'success',
                      message: this.$t('order.deleteOrder.success')
                    });
                    this.getData();
                  }
                })
                .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('order.deleteOrder.cancelDelete')
            });
          });
    },
    handleStockQuantityClose() {
      this.stockQuantityVisible = false
    },
    showStockQuantityDetail(ItemId) {
      this.currentItemId = ItemId
      this.stockQuantityVisible = true
    },
    bookToBill(row) {
      let orderIds;
      let message;

      if (row) {
        // 单个发货
        orderIds = [row.Id];
        message = `是否对订单编号为（${row.MainBillCode}）的订单出货，是否继续？`;
      } else if (this.multipleSelection.length) {
        // 批量发货
        orderIds = this.multipleSelection.map(item => item.Id);
        message = `是否对选中的 ${orderIds.length} 个订单进行发货操作，是否继续？`;
      } else {
        this.$message.warning('请选择要发货的订单');
        return;
      }

      this.$confirm(
          message,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          OrderIds: orderIds
        }
        orderToDelivery(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success(row ? "发货成功" : "批量发货成功")
            this.getData()
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
    getClasses(state) {
      if (state === 4) {
        return "info-ball"
      } else if (state === 1) {
        return "success-ball"
      } else if (state === 2) {
        return "warning-ball"
      } else {
        return "half-warning-ball"
      }
    },
    handleCommand(event, row) {
      if (event === 'unBind') {
        this.unBindSku(row)
      } else if (event === 'order') {
        this.byteToPdf(row.MainBillCode)
      } else if (event === 'detail') {
        this.showOutBoundDetail(row.Id)
      }
    },
    unBindSku(row) {
      this.$confirm(`该操作会解除编号为（${row.MainBillCode}）的SKU库存是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          Id: row.Id
        }
        unBoundStock(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success("解除绑定成功")
            this.getData()
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
    orderStatusFormatter(row, column, cellValue) {
      if (cellValue === 1) {
        return "未发货"
      } else if (cellValue === 2) {
        return "待发货"
      } else {
        return "已发货"
      }
    },
    clearQuery() {
      this.PageIndex = 1
      this.getData()
    },
    resetForm() {
      this.querys = {
      }
      this.drawerQuery = {
        dateRange: []
      }
      this.queryForm = {}
      this.clearQuery()
    },
    showOutBoundDetail(OrderId) {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        OrderId: OrderId
      }
      getOrderItemDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.outBoundData = Data.map(item => {
            return {
              ...item,
              SkuDetail: {}
            }
          })
          this.currentStateId = OrderId
          this.detailVisible = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    outBound(OrderId, WareHouseId) {
      const data = {
        PageIndex: 1,
        PageRows: 10,
        OrderId: OrderId
      }
      getOrderItemDetail(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.outBoundData = Data.map(item => {
            return {
              ...item,
              SkuDetail: {},
              outBoundAmount: item.Amount || 0
            }
          })
          this.currentStateId = OrderId
          this.WareHouseId = WareHouseId
          this.visible = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleVisibleClose() {
      this.visible = false
    },
    handleDetailClose() {
      this.detailVisible = false
    },
    byteToPdf(orderId) {
      const data = {
        BillCodes: [orderId],
        TemplateName: "虾皮拣货单"
      }
      getSheetByte(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          // const pdfurl = `data:application/pdf;base64,${Data[0]}`
          printJS({
            printable: Data[0].File,
            type: 'pdf',
            base64: true
          })
        } else {
          this.$message.warning(Msg)
        }

      })
    },
    handleRefresh() {
      this.handleVisibleClose()
      this.getData()
    },
    getData() {
      let QueryCodes = this.querys.code ? this.querys.code.split(/[，,\s\r\n\t]+/) : undefined
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        Codes: [],
        QueryCodeType: this.queryType,
        QueryCodes: QueryCodes,
        CarrierId: this.drawerQuery.carrier || undefined,
        WareHouseIds: this.drawerQuery.warehouse ? [this.drawerQuery.warehouse] : undefined,
        DestinationId: this.drawerQuery.destination || undefined,
        StartTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[0] : undefined,
        EndTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[1] : undefined,
        QueryType: this.drawerQuery.timeType,
        OrderSource: this.OrderSource,
        ElectronicShopId: this.drawerQuery.ElectronicShopId || undefined,
        Rem: this.querys.rem,
        IsCancel: this.drawerQuery.orderType || undefined,
        StockState: this.drawerQuery.StockState || undefined,
        OrderStatu: this.drawerQuery.OrderStatu || this.OrderStatu
      }
      this.loading = true
      getOrderList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.total = Total
          this.tableData = Data
          this.$nextTick(() => {
            this.$refs.multipleTable.bodyWrapper.scrollTop = 0
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    tableRowClassName({ row }) {
      return row.IsCancel ? 'canceled-row' : '';
    },
    handleBatchBookToBill() {
      const orderIds = this.multipleSelection.map(item => item.Id);

      this.$confirm(
          `是否对选中的 ${orderIds.length} 个订单进行发货操作，是否继续？`,
          "提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: 'warning'
          }
      ).then(() => {
        const data = {
          OrderIds: orderIds
        }
        orderToDelivery(data).then(response => {
          const { Code, Msg } = response
          if (Code === 200) {
            this.$message.success("批量发货成功")
            this.getData()
          } else {
            this.$message.warning(Msg)
          }
        })
      })
    },
  }
}

</script>

<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.default-font {
  width: 60px;
}
.warning-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: #ef0606;
}

// 付款状态标签样式优化
.arrived-box,
.unarrive-box {
  min-width: 72px;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  border-radius: 13px;
  transition: all 0.2s;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 10px;
}

.arrived-box {
  background: rgba(103, 194, 58, 0.1);
  color: #67C23A;
  border: 1px solid rgba(103, 194, 58, 0.2);

  &:hover {
    background: rgba(103, 194, 58, 0.2);
  }
}

.unarrive-box {
  background: rgba(245, 108, 108, 0.1);
  color: #F56C6C;
  border: 1px solid rgba(245, 108, 108, 0.2);

  &:hover {
    background: rgba(245, 108, 108, 0.2);
  }
}

.half-warning-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: linear-gradient(to right, #08c421 50%, #ef0606 50%);
}

.success-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: #08c421;
}

.info-ball {
  width: 12px;
  height: 12px;
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: #727070;
}
::v-deep .success-row {
  background: #f0f9eb;
}
::v-deep .header-row {
  background: oldlace !important;
}
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.shopee-img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}
.order-container {
  padding: 0.5rem 1rem;
  background: #f5f7fa;
  height: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  // 搜索区域式优化
  .search-section {
    flex-shrink: 0;
    background: white;
    padding: 1.2rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;

    // 展开搜索区域样式
    .expanded-search {
      background: #f8f9fb;
      padding: 20px;
      border-radius: 4px;
      margin-top: 10px;
    }

    .search-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.8rem;

      .search-input {
        width: 20rem;

        ::v-deep .el-input__inner {
          height: 28px;
          line-height: 28px;
          border-radius: 4px;

          &:hover {
            border-color: #409EFF;
          }
        }

        ::v-deep .el-input__prefix {
          left: 8px;
          height: 28px;
          line-height: 28px;
          color: #909399;
        }

        ::v-deep .el-input__inner {
          padding-left: 30px;
        }
      }

      // 新增展开按钮样式
      .expand-button {
        padding: 0 15px;
        height: 28px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #606266;
        font-size: 12px;
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        background: #f5f7fa;
        margin-left: 0;
        user-select: none;

        &:hover {
          background: #e6e8eb;
          color: #409EFF;
        }

        i {
          margin-right: 6px;
          font-size: 12px;
          transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &.expanded {
          background: #ecf5ff;
          color: #409EFF;
          border-color: #b3d8ff;

          i {
            transform: rotate(180deg);
          }
        }
      }

      .filter-badge {
        margin: 0;
      }

      .export-excel-wrapper {
        margin: 0;
      }
    }
  }

  // 状态指示器样式优化
  .status-indicators {
    flex-shrink: 0;
    background: white;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: flex;
    gap: 2rem;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    .status-item {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      font-size: 1.3rem;
      height: 1.3rem;
      color: #606266;

      .status-dot {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
      }
    }
  }

  // 表格容器样式优化
  .custom-table {
    flex: 1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);

    ::v-deep {
      .el-table__body-wrapper {
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #dcdfe6;

          &:hover {
            background-color: #c0c4cc;
          }
        }

        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #f5f7fa;
        }
      }

      .el-table__body-wrapper--horizontal-scroll {
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #dcdfe6;

          &:hover {
            background-color: #c0c4cc;
          }
        }

        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background-color: #f5f7fa;
        }
      }

      .el-table__header-wrapper {
        th {
          background: #f5f7fa;
          color: #606266;
          font-weight: 600;
        }
      }

      .el-table__row {
        &:hover > td {
          background: #f5f7fa !important;
        }
      }

      .el-table__expand-icon {
        color: #409EFF;
      }
    }
  }

  // 分页器样式优化
  .custom-pagination {
    flex-shrink: 0;
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background: white;
    border-radius: 8px;
    text-align: right;

    ::v-deep {
      .el-pagination__total {
        font-size: 1.3rem;
      }

      .el-pagination__sizes {
        margin-left: 1rem;
      }

      .el-pager li {
        border-radius: 4px;

        &.active {
          background-color: #409EFF;
        }
      }
    }
  }

  // 状态球样式优化
  .warning-ball {
    background: #f56c6c;
  }

  .half-warning-ball {
    background: linear-gradient(to right, #67c23a 50%, #f56c6c 50%);
  }

  .success-ball {
    background: #67c23a;
  }

  .info-ball {
    background: #909399;
  }

  // 图片样式优化
  .shopee-img {
    width: 4rem;
    height: 4rem;
    border-radius: 4px;
    object-fit: cover;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }
}

// 全局滚动条样式（如果需要的话）
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #dcdfe6;

  &:hover {
    background-color: #c0c4cc;
  }
}

::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #f5f7fa;
}

::v-deep .canceled-row {
  // 调整为更深的灰色
  background-color: #f0f0f0 !important;
  color: #909399 !important; // 添加文字颜色

  // 让鼠标悬停时保持相同的背景色
  &:hover > td {
    background-color: #f0f0f0 !important;
  }

  // 设置展开行的背景色
  .el-table__expand-column {
    .el-table__expand-inner {
      background-color: #f0f0f0;
    }
  }

  // 设置展开内容的背景色
  .el-table__expanded-cell {
    background-color: #f0f0f0 !important;

    // 设置展开内容中的表格背景色
    .el-table {
      background-color: #f0f0f0;

      // 设置表头背景和文字颜色
      th {
        background-color: #e8e8e8 !important;
        color: #909399 !important;
      }

      // 设置展开内容中表格的行背景色
      tr {
        background-color: #f0f0f0 !important;
        color: #909399 !important;

        &.success-row {
          background-color: #f0f0f0 !important;
        }
      }

      // 设置展开内容中表格的单元格背景色
      td {
        background-color: #f0f0f0 !important;
        color: #909399 !important;
      }
    }
  }

  // 确保所有文字都变灰
  td {
    color: #909399 !important;
  }
}

// 确保展开行的样式正确应用
::v-deep .el-table__expanded-cell {
  padding: 20px !important;
}

::v-deep .canceled-detail-row {
  background-color: #f0f0f0 !important;
  color: #909399 !important;

  &:hover > td {
    background-color: #f0f0f0 !important;
  }

  td {
    background-color: #f0f0f0 !important;
    color: #909399 !important;
  }
}

::v-deep .canceled-header-row {
  background-color: #e8e8e8 !important;
  color: #909399 !important;

  th {
    background-color: #e8e8e8 !important;
    color: #909399 !important;
  }
}

.stock-link {
  color: #409EFF;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: #66b1ff;
    text-decoration: underline;
  }

  .el-icon-view {
    font-size: 14px;
  }
}

.search-content {
  .el-button {
    margin-left: 8px;
  }
}

.el-button [class^="el-icon-"] {
  font-size: 16px;
}


.mr10 {
  margin-right: 10px;
}

.el-dropdown-menu {
  .el-icon-document,
  .el-icon-edit-outline,
  .el-icon-link,
  .el-icon-scissors,
  .el-icon-search,
  .el-icon-circle-close {
    margin-right: 5px;
    font-size: 14px;
  }
}
</style>
