<template>
  <div class="upload-container">
    <div class="queryItems">
      <el-button :disabled="uploadProcess" @click="submit" type="success" size="mini">
        {{ $t('upload.save') }}<i :class="uploadIcon"></i>
      </el-button>
      <el-upload
          action="#"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handleFileSelect"
          accept=".xls,.xlsx"
      >
        <el-button
            style="margin-right: 10px; margin-left: 10px"
            type="info"
            size="mini"
        >
          {{ $t('upload.selectFile') }}<i class="el-icon-upload el-icon--right"></i>
        </el-button>
      </el-upload>
      <el-button @click="exportExcel" type="primary" size="mini">
        {{ $t('upload.downloadTemplate') }}<i class="el-icon-download el-icon--right"></i>
      </el-button>
      <el-button @click="deleteTable" type="danger" size="mini">
        {{ $t('upload.delete') }}<i class="el-icon-delete el-icon--right"></i>
      </el-button>
    </div>
    <div class="data-body">
      <el-table
          v-loading="loading"
          :element-loading-text="$t('upload.loadingText')"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          ref="multipleTable"
          :height="tableHeight"
          :max-height="tableHeight"
          fit
          :auto-resize="true"
          @selection-change="handleSelectionChange"
          size="mini"
          border
          :data="tableData"
          style="width: 100%"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
            prop="ErrorMsg"
            :width="errorColumnWidth"
            :label="$t('upload.errorMsg')"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.ErrorMsg" class="error-message">
              <i class="el-icon-warning"></i>
              <span class="error-text">{{ scope.row.ErrorMsg }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="MainBillCode" show-overflow-tooltip width="150" :label="$t('upload.mainBillCode')"></el-table-column>
        <el-table-column show-overflow-tooltip width="150" prop="CarrierBillCode" :label="$t('upload.carrierBillCode')"></el-table-column>
        <el-table-column v-if="menu === 'back'" prop="ReturnCode" label="退货单号"></el-table-column>
        <el-table-column prop="SubbillNumber" :label="$t('upload.subbillNumber')"></el-table-column>
        <el-table-column v-if="menu === 'cod'" prop="SKUTxt" :label="$t('upload.skuCode')"></el-table-column>
        <el-table-column v-if="menu === 'same'" prop="ParcelCodesTxt" :label="$t('upload.parcelCode')"></el-table-column>
        <el-table-column prop="ClientRem" :label="$t('upload.clientRem')"></el-table-column>
        <el-table-column prop="Rem" :label="$t('upload.rem')"></el-table-column>
        <el-table-column prop="GoodsTypeTxt" :label="$t('upload.goodsTypeId')"></el-table-column>
        <el-table-column prop="TimerTxt" :label="$t('upload.timerId')"></el-table-column>
        <el-table-column prop="CarrierTxt" :label="$t('upload.carrierId')"></el-table-column>
        <el-table-column prop="GoodsCount" :label="$t('upload.goodsCount')"></el-table-column>
        <el-table-column prop="GoodsName" :label="$t('upload.goodsName')"></el-table-column>
        <el-table-column prop="GoodsName_En" :label="$t('upload.goodsNameEn')"></el-table-column>
        <el-table-column prop="DestinationTxt" :label="$t('upload.destinationId')"></el-table-column>
        <el-table-column prop="ProvinceTxt" :label="$t('upload.province')"></el-table-column>
        <el-table-column prop="CityTxt" :label="$t('upload.city')"></el-table-column>
        <el-table-column prop="AreaTxt" :label="$t('upload.area')"></el-table-column>
        <el-table-column prop="CollectionAmount" :label="$t('upload.collectionAmount')"></el-table-column>
        <el-table-column prop="InsuredAmount" :label="$t('upload.insuredAmount')"></el-table-column>
        <el-table-column prop="Weight" :label="$t('upload.weight')"></el-table-column>
        <el-table-column prop="IsTax" :label="$t('upload.isTax')"></el-table-column>
        <el-table-column prop="AgentPointTxt" :label="$t('upload.agentPointId')"></el-table-column>
        <el-table-column prop="IdCard" :label="$t('upload.idCard')" width="100"></el-table-column>
        <el-table-column prop="PostNo" :label="$t('upload.postNo')"></el-table-column>
        <el-table-column prop="ReceiveName" :label="$t('upload.receiveName')" width="100"></el-table-column>
        <el-table-column show-overflow-tooltip prop="ReceiveAddress" :label="$t('upload.receiveAddress')" width="150"></el-table-column>
        <el-table-column prop="ReceivePhoneNo" :label="$t('upload.receivePhoneNo')" width="100"></el-table-column>
        <el-table-column prop="ReceiveTel" :label="$t('upload.receiveTel')" width="100"></el-table-column>
        <el-table-column prop="Email" :label="$t('upload.email')"></el-table-column>
        <el-table-column prop="DeclareName" :label="$t('upload.declareName')" width="100"></el-table-column>
        <el-table-column prop="DeclareAddress" :label="$t('upload.declareAddress')" width="100"></el-table-column>
        <el-table-column prop="DeclareTel" :label="$t('upload.declareTel')" width="100"></el-table-column>
        <el-table-column prop="DeclarePhoneNo" :label="$t('upload.declarePhoneNo')" width="100"></el-table-column>
        <el-table-column prop="DeclareIdCard" :label="$t('upload.declareIdCard')"></el-table-column>
        <el-table-column prop="Declareprice" :label="$t('upload.declarePrice')"></el-table-column>
        <el-table-column prop="DeclareCount" :label="$t('upload.declareCount')"></el-table-column>
        <el-table-column prop="DeclareValue" :label="$t('upload.declareValue')"></el-table-column>
        <el-table-column prop="Length" :label="$t('upload.length')"></el-table-column>
        <el-table-column prop="Width" :label="$t('upload.width')"></el-table-column>
        <el-table-column prop="Height" :label="$t('upload.height')"></el-table-column>
        <el-table-column prop="StoreName" label="门店名称"></el-table-column>
        <el-table-column prop="StoreNumber" label="门店号码"></el-table-column>
        <el-table-column prop="CreateTime" :label="$t('upload.createTime')"></el-table-column>
        <el-table-column prop="Rem1" label="备注1"></el-table-column>
        <el-table-column prop="Rem2" label="备注2"></el-table-column>
        <el-table-column prop="Rem3" label="备注3"></el-table-column>
        <el-table-column prop="Rem4" label="备注4"></el-table-column>
      </el-table>
      <el-pagination
          class="mt20"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="PageIndex"
          :page-sizes="PageSizes"
          :page-size="PageRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
        :title="$t('upload.dialogTitle')"
        width="360px"
        :visible.sync="visible"
        class="warehouse-dialog"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
        <el-form-item :label="$t('upload.warehouseLabel')" prop="warehouse">
          <el-select
              v-model="ruleForm.warehouse"
              style="width: 100%"
              :placeholder="$t('upload.placeholderWarehouse')"
          >
            <el-option
                v-for="item in options"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button
              class="submit-btn"
              v-loading="uploadProcess"
              element-loading-spinner="el-icon-loading"
              type="primary"
              @click="submitForm"
          >
            {{ $t('upload.submitButton') }}
          </el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as xlsx from 'xlsx'
import {downloadTemplate, uploadOrder, validateTemplate} from "@/api/system";
import pagination from "@/utils/mixin/pagination";
export default {
  mixins: [ pagination ],
  props: {
    menu: {
      type: String,
      default: "same"
    }
  },
  data() {
    return {
      listData: [],
      uploadProcess: false,
      visible: false,
      loading: false,
      ruleForm: {},
      multipleSelection: [],
      options: [],
      rules: {
        warehouse: [
          { required: true, message: this.$t('upload.placeholderWarehouse'), trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.$store.dispatch('webSite/getWareHouse').then(data => {
      this.options = data
    })
  },
  computed: {
    OrderType() {
      if (this.menu === 'same') {
        return 3
      } else if (this.menu === 'cod') {
        return 5
      } else {
        return 10
      }
    },
    templateName() {
      if (this.menu === 'same') {
        return "同行订单导入模板.xls"
      } else if (this.menu === 'cod') {
        return "COD订单导入模板.xls"
      } else {
        return "重出订单导入模板.xls"
      }
    },
    uri() {
      if (this.menu === 'same') {
        return "/member/same/order"
      } else if (this.menu === 'cod') {
        return "/member/cod/order"
      } else {
        return "/member/back/order"
      }
    },
    tableHeight() {
      return 'calc(100vh - 16rem - 61px)'
    },
    errorColumnWidth() {
      const currentPageData = this.tableData
      if (!currentPageData.length) return 150

      const maxLength = Math.max(...currentPageData
          .filter(item => item.ErrorMsg)
          .map(item => item.ErrorMsg.length)
      )

      return Math.max(150, maxLength * 14 + 30)
    },
    uploadIcon() {
      if (this.uploadProcess) {
        return 'el-icon-loading el-icon--left'
      } else {
        return 'el-icon-circle-check el-icon--right'
      }
    },
    tableData() {
      const sortedData = [...this.listData].sort((a, b) => {
        if (a.ErrorMsg && !b.ErrorMsg) return -1;
        if (!a.ErrorMsg && b.ErrorMsg) return 1;
        return 0;
      });
      return sortedData.slice(((this.PageIndex - 1) * this.PageRows), this.PageIndex * this.PageRows);
    },
    deleteOrderCode() {
      return this.multipleSelection.map(item => { return item['MainBillCode'] }).join(',')
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportExcel() {
      try {
        downloadTemplate().then(response => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a');
          link.href = url
          link.setAttribute('download', this.templateName)
          document.body.appendChild(link);
          link.click()
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          this.$message.success(this.$t('upload.downloadSuccess'))
        })
      } catch (e) {
        this.$message.warning(this.$t('upload.downloadError', [e]))
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const data = {
            WarehouseId: this.ruleForm.warehouse,
            OrderList: this.listData,
            OrderType: this.OrderType
          }
          this.uploadProcess = true
          uploadOrder(data).then(response => {
            this.uploadProcess = false
            const { Code, Msg } = response
            if (Code === 200) {
              this.$message.success(this.$t('upload.exportSuccess'))
              setTimeout(() => {
                this.$router.push({
                  path: this.uri
                })
              }, 500)
              this.visible = false
            } else {
              this.$message.warning(Msg)
            }
          }).catch((e) => {
            this.uploadProcess = false
            this.$message.warning(e)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submit() {
      if (this.listData.filter(item => { return item.ErrorMsg }).length) {
        this.$message.warning(this.$t('upload.validateError'))
        return
      }
      if (this.listData.length) {
        this.visible = true
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    validateType() {
      const skus = this.listData.filter(item => {
        return item.SKU
      })
      const reappear = this.listData.filter(item => {
        return item['退货单号']
      })
      const parcel = this.listData.filter(item => {
        return item['包裹单号']
      })
      if (this.menu === 'same' && (skus.length || reappear.length)) {
        this.$message.error("同行导入请不要填写SKU或重出信息")
        return false
      } else if (this.menu === 'cod' && (reappear.length || parcel.length)) {
        this.$message.error("COD导入请不要填写包裹或重出信息")
        return false
      } else if (this.menu === 'back' && (skus.length || parcel.length)) {
        this.$message.error("重出导入请不要填写SKU或包裹信息")
        return false
      }
      return true
    },
    handleCurrentChange(val) {
      this.pageIndex = val
    },
    excelToJson() {
      return this.listData.map(item => {
        let data = {
          MainBillCode: item['订单号'],
          CarrierBillCode: item['承运单号'],
          SubbillNumber: item['子单号'],
          ClientRem: item['客户备注'],
          Rem: item['订单备注'],
          GoodsTypeId: item['货物类型'],
          TimerId: item['时效'],
          CarrierId: item['承运商'],
          GoodsCount: item['货物数量'] || 0,
          GoodsName: item['品名'],
          GoodsName_En: item['英文品名'],
          DestinationId: item['目的地'],
          Province: item['省份'],
          City: item['城市'],
          Area: item['区域'],
          CollectionAmount: item['代收货款'] || 0,
          InsuredAmount: item['保价金额'] || 0,
          Weight: item['实际重量'] || 0,
          IsTax: item['是否包税(1包税,其他不包税)'] || 0,
          AgentPointId: item['自提点'],
          IdCard: item['身份证号码'],
          PostNo: item['邮政编码'],
          ReceiveName: item['收件人名称'],
          ReceiveAddress: item['收件人地址'],
          ReceivePhoneNo: item['收件人手机'],
          ReceiveTel: item['收件人电话'],
          Email: item['邮箱'],
          DeclareName: item['申报人名称'],
          DeclareAddress: item['申报人地址'],
          DeclareTel: item['申报人电话'],
          DeclarePhoneNo: item['申报人手机'],
          DeclareIdCard: item['申报统编'],
          Declareprice: item['申报价格'] || 0,
          DeclareCount: item['申报数量'] || 0,
          DeclareValue: item['申报价值'] || 0,
          CreateTime: item['下单时间'],
          Length: item['长'] || 0,
          Width: item['宽'] || 0,
          Height: item['高'] || 0,
          StoreName: item['门店名称'],
          StoreNumber: item['门店号码'],
          Rem1: item['备注1'],
          Rem2: item['备注2'],
          Rem3: item['备注3'],
          Rem4: item['备注4'],
        }
        if (this.menu === 'same') {
          data["ParcelCode"] = item['包裹单号']
        } else if (this.menu === "cod") {
          data["SKUCode"] = item['SKU']
        } else {
          data["ReturnCode"] = item["退货单号"]
        }
        return data
      })
    },
    deleteTable() {
      this.$confirm(this.$t('upload.message', [this.deleteOrderCode]), this.$t('upload.prompt'), {
        confirmButtonText: this.$t('upload.confirm'),
        cancelButtonText: this.$t('upload.cancel'),
        type: 'warning'
      }).then(() => {
        this.listData = this.listData.filter(item => !this.deleteOrderCode.includes(item.MainBillCode));
        this.$message.success(this.$t('upload.deleteSuccess'))
      })
    },
    afterProcessData() {
      if (!this.validateType()) {
        this.listData = []
        return;
      }
      const data = {
        IsCheck: false,
        orderImportModels: this.excelToJson(),
        OrderType: this.OrderType
      }
      this.loading = true
      validateTemplate(data).then(response => {
        this.loading = false
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.listData = Data
          const CollectionAmounts = this.listData.filter(item => { return item.CollectionAmount })
          if (!CollectionAmounts.length) {
            this.$notify({
              title: '提示',
              message: '当前导入的所有订单都没有代收货款，请确认',
              type: 'warning',
              duration: 5000
            });
          }
          this.total = this.listData.length
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
        } else {
          this.$message.warning(Msg)
          this.listData = []
        }
      }).catch((e) => {
        this.loading = false
        this.$message.warning(e)
      })
    },
    handleFileSelect(event) {
      this.listData = []
      const file = event.raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = xlsx.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: '',
          blankrows: false
        });

        let headers = jsonData[0].filter(header => header !== '');

        jsonData.slice(1).forEach(row => {
          if (row.some(cell => cell !== '')) {
            const rowData = {};
            headers.forEach((header, index) => {
              if (header) {
                rowData[header] = row[index] || '';
              }
            });
            if (Object.values(rowData).some(value => value !== '')) {
              this.listData.push(rowData);
            }
          }
        });

        this.afterProcessData();
      };
      reader.readAsArrayBuffer(file);
    },
  }
}

</script>

<style scoped lang="scss">
.error-message {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background-color: #fef0f0;
  border-radius: 4px;
  color: #f56c6c;
  line-height: 1.4;
  .el-icon-warning {
    margin-right: 6px;
    font-size: 14px;
    flex-shrink: 0;
  }
  .error-text {
    flex: 1;
    overflow: hidden;
  }
}

::v-deep .el-dialog__body {
  padding: 10px;
}
.upload-container {
  padding: 2rem;
  .queryItems {
    display: flex;
    margin-bottom: 2rem;
  }
}

::v-deep .el-table__body-wrapper {
  .el-table__row {
    .el-table_1_column_2 {
      .cell {
        white-space: normal;
        padding: 5px 10px;
      }
    }
  }
}

.warehouse-dialog {
  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .el-form-item__label {
    padding-bottom: 8px;
    font-weight: 500;
  }

  .dialog-footer {
    margin-top: 24px;
    text-align: right;

    .submit-btn {
      width: 100%;
      padding: 12px 20px;
      font-size: 14px;
    }
  }
}
</style>
