<template>
  <div class="order-container">
    <el-alert
      v-if="tableData.length > 1000"
      title="当前数据量较大，建议使用导出功能处理数据"
      type="warning"
      :closable="false"
      show-icon
      class="mb-3"
    />
    <div class="queryItems">
      <div class="search-bar">
        <search-input
            v-model="queryCode"
            :type.sync="queryType"
            :options="queryOptions"
            :placeholder="'请输入搜索内容，多个内容用逗号或换行符分隔'"
            @clear="clearQuery"
            @search="getData"
        />
        <el-badge :hidden="!badgeNum" :value="badgeNum" class="filter-badge">
          <el-button type="default" size="mini" icon="el-icon-s-operation" @click="showFilter">筛选</el-button>
        </el-badge>
        <el-button type="warning" plain size="mini" icon="el-icon-refresh" @click="resetForm">重置</el-button>
        <el-button v-if="false" type="danger" plain size="mini" icon="el-icon-delete" @click="deleteOrder({})">{{ $t('order.delete') }}</el-button>
        <export-excel
            v-if="tableColumns && tableColumns.length"
            :selected-data="multipleSelection"
            :default-columns="tableColumns"
            :all-columns="allColumns"
            :storage-key="columnKey"
            file-name-prefix="重出订单"
        />
        <batch-print :multiple-selection="multipleSelection"></batch-print>
        <el-button type="danger" plain size="mini" icon="el-icon-setting" @click="colDrawer = true">列设置</el-button>

        <div
            class="expand-button"
            :class="{ expanded: isSearchExpanded }"
            @click="isSearchExpanded = !isSearchExpanded"
        >
          <i :class="isSearchExpanded ? 'el-icon-minus' : 'el-icon-plus'"></i>
          {{ isSearchExpanded ? '收起筛选' : '展开筛选' }}
        </div>
      </div>

      <transition name="expand">
        <div v-show="isSearchExpanded" class="expanded-search">
          <advanced-search
            @search="getData"
            @reset="resetAdvanceSearch"
          />
        </div>
      </transition>
    </div>
<!--    <table-copy ref="copyTable" table-selector=".el-table__body-wrapper">-->
      <el-table
          :data="renderData"
          border
          style="width: 100%"
          :height="tableHeight"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          @header-dragend="updateTableHeight"
          :key="tableKey + '-' + tableColumns.length"
      >
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column
            v-for="col in tableColumns"
            :key="col.prop"
            :prop="col.prop"
            :label="col.label"
            sortable
            align="center"
            :width="col.width === 'auto' ? null : col.width"
            :min-width="col.prop === 'CheckCodes' ? carrierBillCodeWidth : col.prop === 'ReceiveAddress' ? receiveAddressWidth : null"
        >
          <template slot-scope="scope">
            <template v-if="col.type === 'status'">
              <div class="arrived-box" v-if="scope.row[col.prop]">{{ $t('order.paid') }}</div>
              <div class="unarrive-box" v-else>{{ $t('order.nonpayment') }}</div>
            </template>

            <template v-else-if="col.type === 'shopeeStatus'">
              <span>{{ orderStatusFormatter(scope.row, col , scope.row[col.prop]) }}</span>
            </template>

            <template v-else-if="col.type === 'link'">
              <div class="carrier-bill-codes">
                <span
                    class="bill-code-link"
                    @click="showTrajectory(scope.row.CarrierBillCode)"
                >
                  {{ scope.row.CarrierBillCode }}
                </span>
              </div>
            </template>

            <template v-else-if="col.type === 'linkGroup'">
              <div class="carrier-bill-codes">
                <span
                    v-for="billCode in scope.row.CheckCodes"
                    :key="billCode"
                    class="bill-code-link"
                    @click="showTrajectory(billCode)"
                >
                  {{ billCode }}
                </span>
              </div>
            </template>

            <template v-else-if="col.type === 'datetime'">
              <span>{{ scope.row[col.prop] | formatDate }}</span>
            </template>

            <template v-else-if="col.type === 'printState'">
              <span>{{ scope.row[col.prop] | formatPrintState }}</span>
            </template>

            <template v-else-if="col.type === 'formatBool'">
              <span>{{ scope.row[col.prop] | formatBool }}</span>
            </template>

            <template v-else>
              {{ scope.row[col.prop] }}
            </template>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            :label="$t('order.operate')"
            min-width="140px"
            align="center">
          <template slot-scope="scope">
            <el-tooltip content="详情" placement="top" :hide-after="1000">
              <el-button
                  type="text"
                  size="mini"
                  @click="showOrder(scope.row.Id)">
                <i class="el-icon-document"></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          class="mt20"
          background
          @size-change="handleSizeChange"
          @current-change="handleChange"
          :current-page="PageIndex"
          :page-sizes="PageSizes"
          :page-size="PageRows"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
<!--    </table-copy>-->
    <el-dialog
        :title="$t('order.orderdetails')"
        :visible.sync="dialogVisible"
        top="1vh"
        center
        :close-on-click-modal="false"
    >
      <order-detail :order="order"></order-detail>
    </el-dialog>
    <trajectory-box @close="trajectoryVisible=false" :show="trajectoryVisible" :carrier-bill-code="carrierBillCode"></trajectory-box>
    <filter-drawer :time-options="timeTypeOptions" :list-data="drawerListData" @handle-confirm="handleConfirm" @close="handleDrawerClose" :drawer="drawer" :initial-query="drawerQuery"></filter-drawer>
    <column-set-drawer @close="handleColumnClose" @save="handleColumnSave" :show="colDrawer" :storage-key="columnKey" :default-columns="defaultColumns" :all-columns="allColumns"></column-set-drawer>
  </div>
</template>

<script>
import {deleteOrder, deliveryOrder, getOrderDetail, getOrderList, getSheetByte} from "@/api/member";
import OrderDetail from "@/views/member/order/components/order-detail.vue"
import printJS from "print-js";
import FilterDrawer from "@/components/FilterDrawer/index.vue";
import TableCopy from '@/components/TableCopy'
import ColumnSetDrawer from "@/components/ColumnSetDrawer.vue";
import SearchInput from '@/components/SearchInput'
import batchPrint from "@/components/BatchPrint/index.vue";
import pagination from "@/utils/mixin/pagination";
import FilterDrawers from "@/utils/mixin/filterDrawers";
import ColumnSet from "@/utils/mixin/columnSet";
import ExportExcel from "@/components/ExportExcel/index.vue";
import TrajectoryBox from "@/views/member/order/components/TrajectoryBox.vue";
import AdvancedSearch from "@/components/AdvancedSearch/index.vue";

export default {
  mixins: [ pagination, FilterDrawers, ColumnSet ],
  components: {
    TrajectoryBox,
    ExportExcel,
    // eslint-disable-next-line vue/no-unused-components
    ColumnSetDrawer, OrderDetail, FilterDrawer, TableCopy, SearchInput, batchPrint,
    AdvancedSearch
  },
  data() {
    return {
      trajectoryVisible: false,
      columnKey: "all",
      queryCode: '',
      queryType: 3,
      queryOptions: [
        { label: '订单号', value: 3 },
        { label: '承运单号', value: 4 },
      ],
      dialogVisible: false,
      multipleSelection: [],
      show: false,
      order: {},
      currentOrderNo: "",
      loading: false,
      tableData: [],
      carrierBillCode: undefined,
      orderId: undefined,
      isSearchExpanded: false,
      currentTableHeight: 'calc(100vh - 17.2rem - 58px)',
      tableKey: 0,
      itemSize: 50, // 每行的高度
      visibleCount: 20, // 可视区域显示的行数
      startIndex: 0, // 起始索引
      loadingMore: false,
      loadedRows: 0,
      batchSize: 1000, // 每批加载的数据量
      renderData: [], // 当前渲染的数据
      isRendering: false, // 是否正在渲染
      renderBatchSize: 100, // 每批渲染的数量
      renderTimeout: null, // 渲染定时器
    }
  },
  mounted() {
    this.initDrawerListData()
    this.getData().then(() => {
      this.$nextTick(() => {
        this.initTable();
      })
    })
  },
  beforeDestroy() {
    // 移除滚动监听
    const tableBody = this.$el.querySelector('.el-table__body-wrapper');
    if (tableBody) {
      tableBody.removeEventListener('scroll', this.handleScroll);
    }
    if (this.renderTimeout) {
      clearTimeout(this.renderTimeout);
    }
  },
  filters: {
    formatBool(val) {
      if (val) return "是"
      return "否"
    },
    formatPrintState(val) {
      if (val) return "已打印"
      return "未打印"
    },
  },
  watch: {
    isSearchExpanded(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          this.currentTableHeight = 'calc(100vh - 17.2rem - 58px - 200px)';
        } else {
          this.currentTableHeight = 'calc(100vh - 17.2rem - 58px)';
        }
      });
    },
    tableColumns: {
      handler() {
        // 当列配置发生变化时，先设置为 auto
        this.currentTableHeight = 'auto';

        // 等待 DOM 更新完成后再设置固定高度
        this.$nextTick(() => {
          setTimeout(() => {
            this.currentTableHeight = this.isSearchExpanded
              ? 'calc(100vh - 17.2rem - 58px - 200px)'
              : 'calc(100vh - 17.2rem - 58px)';
          }, 100);
        });
      },
      deep: true
    },
    currentTableHeight(newVal) {
      console.log('Table height changed to:', newVal);
    }
  },
  computed: {
    tableHeight() {
      return this.currentTableHeight;
    },
    receiveAddressWidth() {
      if (!this.tableData || !this.tableData.length) return 120;

      // 找出所有运单号中最长的一个
      const maxLength = Math.max(...this.tableData.map(row => {
        return row.ReceiveAddress.length;
      }));

      // 根据最长运单号计算列宽
      // 每个字符按8px计算，再加上padding和边距
      const width = Math.max(maxLength * 8 + 32, 120);

      return width;
    },
    carrierBillCodeWidth() {
      if (!this.tableData || !this.tableData.length) return 120;

      // 找出所有运单号中最长的一个
      const maxLength = Math.max(...this.tableData.map(row => {
        if (!row.CheckCodes || !row.CheckCodes.length) return 0;
        return Math.max(...row.CheckCodes.map(code => code.length));
      }));

      // 根据最长运单号计算列宽
      // 每个字符按8px计算，再加上padding和边距
      const width = Math.max(maxLength * 8 + 32, 120);

      return width;
    },
    visibleData() {
      const start = this.startIndex;
      const end = Math.min(start + this.visibleCount, this.tableData.length);
      return this.tableData.slice(start, end);
    }
  },
  methods: {
    orderStatusFormatter(row, column, cellValue) {
      if (cellValue === 1) {
        return "未发货"
      } else if (cellValue === 2) {
        return "待发货"
      } else {
        return "已发货"
      }
    },
    initDrawerListData() {
      this.drawerListData.push({
        title: "打印状态",
        field: "PrintState",
        options: [
          {
            label: "不限",
            value: 0
          },
          {
            label: "未打印",
            value: 1
          },
          {
            label: "已打印",
            value: 2
          }
        ]
      })
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.drawerListData.push({
          title: "仓库",
          field: "warehouse",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
      this.$store.dispatch('webSite/getCountry').then(data => {
        this.drawerListData.push({
          title: "目的地",
          field: "destination",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
      this.$store.dispatch('webSite/getGoodsType').then(data => {
        this.drawerListData.push({
          title: "货物类型",
          field: "goodsType",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
      this.$store.dispatch('webSite/getCarrier').then(data => {
        this.drawerListData.push({
          title: "承运商",
          field: "carrier",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
      this.$store.dispatch('webSite/getTimer').then(data => {
        this.drawerListData.push({
          title: "时效",
          field: "timer",
          options: data.map(item => {
            return {
              label: item.Name,
              value: item.Id
            }
          })
        })
      })
    },
    clearQuery() {
      this.queryCode = ""
      this.PageIndex = 1
      this.getData()
    },
    resetForm() {
      this.queryCode = ""
      this.drawerQuery = {
        dateRange: []
      }
      this.clearQuery()
    },
    deliveryOrder(item) {
      this.$confirm(this.$t('order.deliveryOrder.confirm', [item.Id]), this.$t('order.deliveryOrder.prompt'),
          {
            confirmButtonText: this.$t('order.deliveryOrder.confirmText'),
            cancelButtonText: this.$t('order.deliveryOrder.cancelText'),
            type: 'warning'
          }
      )
          .then(() => {
            const data = {
              Id: item.Id
            }
            deliveryOrder(data)
                .then(response => {
                  if (response.Success) {
                    this.$message({
                      type: 'success',
                      message: this.$t('order.deliveryOrder.success')
                    });
                    this.getData();
                  }
                })
                .catch(() => {});
          })
          .catch(() => {this.$message({type: 'info', message: this.$t('order.deliveryOrder.cancel')});
          });
    },
    closeVisible() {
      this.commonVisible = false
      this.getData()
    },
    showTrajectory(code) {
      this.carrierBillCode = code
      this.trajectoryVisible = true
    },
    closeDialog(flag) {
      this.show = false
      if (flag === 'refresh') {
        this.getData()
      }
    },
    showRateVisible(row) {
      this.orderId = row.Id
      this.commonVisible = true
    },
    printPDF(row) {
      const data = {
        BillCodes: [row.MainBillCode],
        TemplateName: this.printTemplate === 1 ? "拣货单" : undefined
      }
      getSheetByte(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          printJS({
            printable: Data[0].File,
            type: 'pdf',
            base64: true
          })
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    showOrder(id) {
      const data = {
        OrderId: id
      }
      getOrderDetail(data).then(response => {
        const { Code, Data, Msg } = response
        if (Code === 200) {
          this.order = Data;
          this.$forceUpdate()
          this.dialogVisible = true
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    deleteOrder(row = {}) {
      let orderId = '';
      if (Object.keys(row).length) {
        orderId = row.Id;
      } else {
        orderId = this.multipleSelection.map(item => item.Id).join('、');
      }
      this.$confirm(
          this.$t('order.deleteOrder.confirm', { orderId }),
          this.$t('order.deleteOrder.prompt'),
          {
            confirmButtonText: this.$t('order.deleteOrder.confirmText'),
            cancelButtonText: this.$t('order.deleteOrder.cancelText'),
            type: 'warning'
          }
      )
          .then(() => {
            let data = Object.keys(row).length ? [row.Id] : this.multipleSelection.map(item => item.Id);
            data = {
              Ids: data,
              IsRefund: true
            }
            deleteOrder(data)
                .then(response => {
                  if (response.Success) {
                    this.$message({
                      type: 'success',
                      message: this.$t('order.deleteOrder.success')
                    });
                    this.getData();
                  }
                })
                .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('order.deleteOrder.cancelDelete')
            });
          });
    },
    showPayment(row) {
      this.currentOrderNo = row.MainBillCode;
      this.show = true;
    },
    getData() {
      // 使用正则表达式分割输入内容为数组
      let QueryCodes = this.queryCode ?
          this.queryCode.split(/[,，\s\r\n\t]+/).filter(item => item.trim()) :
          undefined

      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows, // 恢复原来的分页大小
        Codes: [],
        QueryCodeType: this.queryType,
        QueryCodes: QueryCodes,
        CarrierId: this.drawerQuery.carrier || undefined,
        TimerId: this.drawerQuery.timer || undefined,
        GoodsTypeId: this.drawerQuery.goodsType || undefined,
        StartTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[0] : undefined,
        EndTime: this.drawerQuery.dateRange && this.drawerQuery.dateRange.length ? this.drawerQuery.dateRange[1] : undefined,
        WareHouseIds: this.drawerQuery.warehouse ? [this.drawerQuery.warehouse] : undefined,
        DestinationId: this.drawerQuery.destination || undefined,
        QueryType: this.drawerQuery.timeType,
        PrintState: this.drawerQuery.PrintState || undefined
      }

      this.loading = true
      return getOrderList(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          document.documentElement.scrollTop = 0;
          this.total = Total
          this.tableData = Data

          // 使用分批渲染
          this.batchRenderData();

          if (Data.length > 1000) {
            this.$message({
              message: '当前数据量较大，建议使用导出功能进行数据处理',
              type: 'warning',
              duration: 5000
            });
          }
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    resetAdvanceSearch() {
      // 重置高级搜索表单
      this.$refs.advanceSearch && this.$refs.advanceSearch.resetForm()
      this.getData()
    },
    updateTableHeight() {
      console.log('updateTableHeight called');
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          this.currentTableHeight = this.isSearchExpanded
            ? 'calc(100vh - 17.2rem - 58px - 200px)'
            : 'calc(100vh - 17.2rem - 58px)';
          console.log('New height set:', this.currentTableHeight);
        });
      });
    },
    handleScroll(e) {
      const scrollTop = e.target.scrollTop;
      this.startIndex = Math.floor(scrollTop / this.itemSize);
    },
    initTable() {
      const tableBody = this.$el.querySelector('.el-table__body-wrapper');
      if (tableBody) {
        tableBody.addEventListener('scroll', this.handleScroll);
        // 计算可视区域能显示的行数
        this.visibleCount = Math.ceil(tableBody.clientHeight / this.itemSize) + 5; // 多渲染5行作为缓冲
      }
    },
    // 优化表格渲染性能
    optimizeTableRender() {
      // 如果数据量大于阈值，使用分批渲染
      if (this.tableData.length > 1000) {
        this.$nextTick(() => {
          const rows = this.$el.querySelectorAll('.el-table__row');
          if (rows.length > 1000) {
            // 添加虚拟滚动的样式类
            this.$el.querySelector('.el-table__body-wrapper').classList.add('optimize-scroll');
          }
        });
      }
    },
    // 分批渲染数据
    batchRenderData() {
      if (this.renderTimeout) {
        clearTimeout(this.renderTimeout);
      }

      this.isRendering = true;
      this.renderData = this.tableData.slice(0, 100); // 先渲染前100条

      const totalLength = this.tableData.length;
      let currentIndex = 100;

      const renderNextBatch = () => {
        if (currentIndex >= totalLength) {
          this.isRendering = false;
          return;
        }

        const nextIndex = Math.min(currentIndex + this.renderBatchSize, totalLength);
        this.renderData = this.tableData.slice(0, nextIndex);
        currentIndex = nextIndex;

        this.renderTimeout = setTimeout(renderNextBatch, 50);
      };

      this.renderTimeout = setTimeout(renderNextBatch, 50);
    }
  },
}

</script>

<style scoped lang="scss">
.order-container {
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  position: relative;

  // 查询区域样式优化
  .queryItems {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ebeef5;
    position: relative;

    // 添加 u-between-flex 样式
    .u-between-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    // 修改搜索栏样式
    .search-bar {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: nowrap;

      > * {
        flex-shrink: 0;
      }

      .search-input {
        flex: 1;
        min-width: 200px;
      }

      // 新增展开按钮样式
      .expand-button {
        padding: 0 15px;
        height: 28px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #606266;
        font-size: 12px;
        transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        background: #f5f7fa;
        margin-left: 10px;
        user-select: none;

        &:hover {
          background: #e6e8eb;
          color: #409EFF;
        }

        i {
          margin-right: 6px;
          font-size: 12px;
          transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
        }

        &.expanded {
          background: #ecf5ff;
          color: #409EFF;
          border-color: #b3d8ff;

          i {
            transform: rotate(180deg);
          }
        }
      }
    }

    // 展开搜索区域样式
    .expanded-search {
      margin-top: 1rem;
      padding: 1.5rem;
      border-top: 1px dashed #e4e7ed;
      background: #f8f9fb;
      border-radius: 4px;
      width: 100%;
      box-sizing: border-box;
      transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.02);
    }
  }

  // 表格样式优化
  ::v-deep .el-table {
    border-radius: 8px;
    overflow: hidden;
    font-size: 12px;

    th {
      background-color: #f5f7fa;
      color: #606266;
      font-weight: 500;
      padding: 8px 0;
      font-size: 12px;
    }

    td {
      padding: 6px 0;
    }

    .cell {
      font-size: 12px;
      line-height: 1.4;

      // 只对需要居中的列用flex布局
      &.is-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    // 运单号列特殊处理
    .carrier-bill-codes {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .bill-code-link {
        font-size: 12px;
        color: #409EFF;
        cursor: pointer;
        padding: 2px 0;
        transition: all 0.2s;

        &:hover {
          color: #66b1ff;
          text-decoration: underline;
        }
      }
    }

    // 调整选择框大小
    .el-checkbox__inner {
      width: 14px;
      height: 14px;
      &::after {
        height: 7px;
        left: 4px;
      }
    }

    // 禁用默认文本选择
    td, th {
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

    // 选中单元格样式
    .selected-cell {
      background-color: rgba(64, 158, 255, 0.1) !important;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid rgba(64, 158, 255, 0.2);
        pointer-events: none;
      }
    }

    // 鼠标样式
    td {
      cursor: cell;

      &:hover {
        background-color: rgba(64, 158, 255, 0.05);
      }
    }

    &__body-wrapper {
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #E6E6E6;
        border-radius: 3px;
      }

      &.optimize-scroll {
        will-change: transform;
        transform: translateZ(0);
      }
    }
  }

  // 标签页样式优化
  ::v-deep .el-tabs--border-card {
    border: none;
    box-shadow: none;
    background: transparent;

    .el-tabs__header {
      background: #fff;
      border: 1px solid #e4e7ed;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      margin: 0;
      padding: 0 16px;
    }

    .el-tabs__nav {
      border: none;
      height: 40px;
    }

    .el-tabs__item {
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      border: none;
      padding: 0 20px;
      color: #606266;
      transition: all 0.2s;
      position: relative;

      &:hover {
        color: #409EFF;
      }

      &.is-active {
        color: #409EFF;
        font-weight: 500;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 24px;
          height: 2px;
          background: #409EFF;
          border-radius: 1px;
        }
      }
    }

    .el-tabs__content {
      border: 1px solid #e4e7ed;
      border-radius: 0 0 4px 4px;
      padding: 16px;
      background: #fff;
    }
  }

  // 付款状态标签样式优化
  .arrived-box,
  .unarrive-box {
    min-width: 72px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    border-radius: 13px;
    transition: all 0.2s;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .arrived-box {
    background: rgba(103, 194, 58, 0.1);
    color: #67C23A;
    border: 1px solid rgba(103, 194, 58, 0.2);

    &:hover {
      background: rgba(103, 194, 58, 0.2);
    }
  }

  .unarrive-box {
    background: rgba(245, 108, 108, 0.1);
    color: #F56C6C;
    border: 1px solid rgba(245, 108, 108, 0.2);

    &:hover {
      background: rgba(245, 108, 108, 0.2);
    }
  }

  // 操作列按钮样式调整
  ::v-deep .el-button--text {
    padding: 4px;
    margin: 0 2px;
    height: 24px;
    width: 24px;
    font-size: 14px;

    i {
      font-size: 14px;
      margin: 0;
    }

    &:hover {
      color: #409EFF;
      background: rgba(64, 158, 255, 0.1);
      border-radius: 4px;
    }

    // 为不同操作添加不同的颜色
    .el-icon-check {
      color: #67C23A;
      &:hover {
        color: #85ce61;
      }
    }

    .el-icon-printer {
      color: #909399;
      &:hover {
        color: #606266;
      }
    }

    .el-icon-chat-dot-round {
      color: #E6A23C;
      &:hover {
        color: #ebb563;
      }
    }

    .el-icon-wallet {
      color: #409EFF;
      &:hover {
        color: #66b1ff;
      }
    }

    .el-icon-document {
      color: #909399;
      &:hover {
        color: #606266;
      }
    }

    .el-icon-delete {
      color: #F56C6C;
      &:hover {
        color: #f78989;
      }
    }
  }

  // Tooltip 样式优化
  ::v-deep .el-tooltip__popper {
    font-size: 12px;
    padding: 6px 8px;
    min-width: 40px;
  }

  // 分页器样式调整
  ::v-deep .el-pagination {
    margin-top: 1.6rem;
    padding: 0.8rem 0;
    font-size: 13px;

    .btn-prev, .btn-next, .el-pager li {
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 13px;
    }
  }
}

// 弹窗样式优化
::v-deep .el-dialog {
  border-radius: 8px;
  overflow: hidden;

  .el-dialog__header {
    padding: 20px;
    background: #f5f7fa;
    margin-right: 0;
  }

  .el-dialog__body {
    padding: 30px 20px;
  }
}

// 选择框样式优化
.selection-box {
  position: absolute;
  border: 2px solid #409EFF;
  background-color: rgba(64, 158, 255, 0.1);
  pointer-events: none;
  z-index: 100;
  box-shadow: 0 0 0 1px rgba(64, 158, 255, 0.2);
}

.expand-enter-active {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: top;
}

.expand-leave-active {
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: top;
}

.expand-enter,
.expand-leave-to {
  transform: scaleY(0);
  opacity: 0;
}

.expand-enter-to,
.expand-leave {
  transform: scaleY(1);
  opacity: 1;
}
</style>
