<template>
  <div class="container" id="printArea">
    <div class="order-box">
      <div class="title">{{ $t('order.title') }}</div>
      <div class="table">
        <div class="item">
          <div class="label brn">{{ $t('order.orderid') }}：{{ order.OrderId }}</div>
          <div class="label brn">{{ $t('order.mainbillcode') }}：{{ order.MainBillCode }}</div>
          <div class="label">{{ $t('order.carrierbillcode') }}：{{ order.CarrierBillCode }}</div>
        </div>
        <div class="item">
          <div class="label brn btn">{{ $t('order.goodstype') }}：{{ order.GoodsTypeName }}</div>
          <div class="label brn btn">{{ $t('order.timer') }}：{{ order.TimerName }}</div>
          <div class="label btn">{{ $t('order.carrier') }}：{{ order.CarrierName }}</div>
        </div>
        <div class="item">
          <div class="label btn">{{ $t('order.goodsname') }}：{{ order.GoodsName }}</div>
        </div>
        <div class="item">
          <div class="label btn">{{ $t('order.orderrem') }}：{{ order.Rem }}</div>
        </div>
      </div>
    </div>
    <div class="delivery-box">
      <div class="title">{{ $t('order.deliverytitle') }}</div>
      <div class="table">
        <div class="item" v-for="(item, i) in order.OrderShipmentDetailsInfos" :key="i">
          <div :class="i !== 0 ? `btn label brn` : `label brn`">{{ $t('order.ordercode') }}：{{ item.OrderCode }}</div>
          <div :class="i !== 0 ? `btn label brn` : `label brn`">{{ $t('order.logistics') }}：{{ item.CarrierName }}</div>
          <div :class="i !== 0 ? `btn label brn` : `label brn`">{{ $t('order.goodsname') }}：{{ item.GoodsName }}</div>
          <div @click="toDownload(item.TaxNoImgURL)" class="download" :class="i !== 0 ? `btn label` : `label`">税金编号：{{ item.TaxNo }}</div>
        </div>
      </div>
    </div>
    <div class="package-box" v-if="order.DedicatedLineParcelInfos.length">
      <div class="title">{{ $t('order.packagetitle') }}</div>
      <div class="package">
        <div class="package-item" v-for="item in order.DedicatedLineParcelInfos" :key="item.Id">
          <div class="u-between-flex">
            <el-image v-if="item.Images" :src="item.Images[0]" :preview-src-list="item.Images" fit="cover" class="package-img"></el-image>
            <el-image v-else :src="require('@/static/images/member/order/nan.png')" fit="cover" class="package-img"></el-image>
            <div class="table">
              <div class="item">
                <el-tooltip class="item" effect="dark" :content="item.WarehouseEntryCode" placement="top-start">
                  <div class="label brn">入仓单号：{{ item.WarehouseEntryCode }}</div>
                </el-tooltip>
                <div class="label brn">批次号：{{ item.BatchNumber }}</div>
                <div class="label">件数：{{ item.Account }}</div>
              </div>
              <div class="item">
                <div class="label brn btn">毛重合计：{{ item.GrossWeightTotal }}kg</div>
                <div class="label brn btn">皮重合计：{{ item.TareWeightTotal }}kg</div>
                <div class="label btn">重量合计：{{ item.WeightTotal }}kg</div>
              </div>
              <div class="item">
                <div class="label btn brn">板数：{{ item.PalletAccount }}</div>
                <div class="label btn brn">体积合计：{{ item.VolumeTotal }}</div>
                <div class="label btn">密度：{{ item.Density }}</div>
              </div>
            </div>
          </div>
          <div style="width: 100%; text-align: center" v-if="!expands[item.WarehouseEntryCode]">
            <el-button @click="expandItem(item.WarehouseEntryCode)" size="small" type="text">
              展开包裹尺寸重量信息
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </div>
          <div class="u-between-flex" style="align-items: flex-start" v-if="expands[item.WarehouseEntryCode]">
            <el-table
                :data="item.WeightInfo"
                size="mini"
                style="width: 40%">
              <el-table-column :label="$t('line.packageWeight')" align="center">
                <el-table-column prop="GrossWeight" :label="$t('line.grossWeightLabel')"></el-table-column>
                <el-table-column prop="TareWeight" :label="$t('line.tareWeightLabel')"></el-table-column>
                <el-table-column prop="Quantity" :label="$t('line.quantity')"></el-table-column>
              </el-table-column>
            </el-table>
            <el-table
                :data="item.SizeInfo"
                size="mini"
                style="width: 60%;">
              <el-table-column :label="$t('line.packageSize')" align="center">
                <el-table-column prop="Length" :label="$t('line.lengthLabel')"></el-table-column>
                <el-table-column prop="Width" :label="$t('line.widthLabel')"></el-table-column>
                <el-table-column prop="Height" :label="$t('line.heightLabel')"></el-table-column>
                <el-table-column prop="Quantity" :label="$t('line.quantity')"></el-table-column>
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 100%; text-align: center" v-if="expands[item.WarehouseEntryCode]">
            <el-button @click="expandItem(item.WarehouseEntryCode)" size="small" type="text">
              收起包裹尺寸重量信息
              <i class="el-icon-arrow-up el-icon--right"></i>
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="package-box" v-if="order.ParcelDetailsInfos.length">
      <div class="title">{{ $t('order.packagetitle') }}</div>
      <div class="package">
        <div class="package-item" v-for="item in order.ParcelDetailsInfos" :key="item.Id">
          <div class="u-between-flex">
            <el-image v-if="item.Images" :src="item.Images[0]" :preview-src-list="item.Images" fit="cover" class="package-img"></el-image>
            <el-image v-else :src="require('@/static/images/member/order/nan.png')" fit="cover" class="package-img"></el-image>
            <div class="table">
              <div class="item">
                <el-tooltip class="item" effect="dark" :content="item.MainCode" placement="top-start">
                  <div class="label brn">{{ $t('order.ordercode') }}：{{ item.MainCode }}</div>
                </el-tooltip>
                <div class="label brn">{{ $t('order.goodsname') }}：{{ item.GoodsName }}</div>
                <div class="label">体积重量：{{ item.VolumeWeight }}kg</div>
              </div>
              <div class="item">
                <el-tooltip class="item" effect="dark" :content="item.ShipmentCode" placement="top-start">
                  <div class="label brn btn">{{ $t('order.shipmentCode') }}：{{ item.ShipmentCode }}</div>
                </el-tooltip>
                <div class="label brn btn">{{ $t('order.account') }}：{{ item.Account }}</div>
                <div class="label btn">{{ $t('order.daysinstorage') }}：{{ item.DaysInStorage }}</div>
              </div>
              <div class="item">
                <div class="label btn brn">{{ $t('order.size') }}：{{ `${item.Length}cm*${item.Width}cm*${item.Height}cm` }}</div>
                <div class="label btn brn">{{ $t('order.weight') }}：{{ item.Weight }}kg</div>
                <div class="label btn">{{ $t('order.surcharge') }}：{{ item.Surcharge }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="total-box">
        <div class="total-item">
          <div class="total-label">{{ $t('order.count') }}：{{ totalCount.count }}</div>
          <div class="total-label">{{ $t('order.grossweight') }}：{{ totalCount.weight }}kg</div>
          <div class="total-label">{{ $t('order.grosssurcharge') }}：{{ totalCount.surcharge }}</div>
          <div class="total-label">{{ $t('order.storagecharges') }}：{{ order.StorageCharges }}</div>
        </div>
        <div class="total-item">
          <div class="total-label">{{ $t('order.weightcharges') }}：<span style="color: #FF870F">{{ order.WeightCharges }}</span></div>
          <div class="total-label">{{ $t('order.lengthcharges') }}：<span style="color: #FF870F">{{ order.LengthCharges }}</span></div>
          <div class="total-label">{{ $t('order.fareweight') }}：<span style="color: #FF870F">{{ order.FareWeight }}kg</span></div>
        </div>
      </div>
    </div>
    <div class="take-delivery-box">
      <div class="title">{{ $t('order.receivinginfo') }}</div>
      <div class="table">
        <div class="item">
          <div class="label">{{ $t('order.receivename') }}：{{ order.ReceiveName }}</div>
          <div class="label">{{ $t('order.receivephone') }}：{{ order.ReceivePhone }}</div>
          <div class="label">{{ $t('order.receivetelephone') }}：{{ order.ReceiveTelephone }}</div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.receiveidcard') }}：{{ order.ReceiveIdCard }}</div>
          <div class="label">{{ $t('order.receivepostcode') }}：{{ order.ReceivePostCode }}</div>
          <div class="label">{{ $t('order.destination') }}：{{ order.DestinationName }}</div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.province') }}：{{ order.ProvinceName }}</div>
          <div class="label">{{ $t('order.city') }}：{{ order.CityName }}</div>
          <div class="label">{{ $t('order.area') }}：{{ order.AreaName }}</div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.receiveaddress') }}：{{ order.ReceiveAddress }}</div>
        </div>
      </div>
    </div>
    <div class="take-delivery-box" v-if="order.SignForReceiptLinks">
      <div class="title">签收单图片</div>
      <div class="table">
        <div style="display: grid; grid-gap: 1rem; grid-template-columns: 1fr 1fr 1fr;">
          <el-image fit="cover" :preview-src-list="order.SignForReceiptLinks" :src="item" style="width: 100%; height: 20rem; border-radius: 1.5rem" v-for="(item, i) in order.SignForReceiptLinks" :key="i"></el-image>
        </div>
      </div>
    </div>
    <div class="declaration-box">
      <div class="title">{{ $t('order.declarationinfo') }}</div>
      <div class="table">
        <div class="item">
          <div class="label">{{ $t('order.declarename') }}：{{ order.DeclareName }}</div>
          <div class="label">{{ $t('order.declarePhone') }}：{{ order.DeclarePhone }}</div>
          <div class="label">{{ $t('order.declaretelephone') }}：{{ order.DeclareTelephone }}</div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.declareidcard') }}：{{ order.DeclareIdCard }}</div>
          <div class="label">{{ $t('order.declarepostcode') }}：{{ order.DeclarePostCode }}</div>
          <div class="label"></div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.declareaddress') }}：{{ order.DeclareAddress }}</div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.declareaccount') }}：{{ order.DeclareAccount }}</div>
          <div class="label">{{ $t('order.declareprice') }}：{{ order.DeclarePrice }}</div>
          <div class="label"></div>
        </div>
      </div>
    </div>
    <div class="fee-box">
      <div class="title">{{ $t('order.feeinfo') }}</div>
      <div class="table">
        <div class="item">
          <div class="label">{{ $t('order.collectionamount') }}：{{ order.CollectionAmount }}</div>
          <div class="label">{{ $t('order.taxfee') }}：{{ order.TaxCommissionInfo }}</div>
          <div class="label">{{ $t('order.insuredamount') }}：{{ order.InsuredAmount }}</div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.couponamount') }}：{{ order.CouponAmount }}</div>
          <div class="label">{{ $t('order.integralamount') }}：{{ order.IntegralAmount }}</div>
          <div class="label">{{ $t('order.destinationcharges') }}：{{ order.DestinationCharges }}</div>
        </div>
        <div class="item btn">
          <div class="label">{{ $t('order.istax') }}：{{ order.IsTax ? $t('order.Yes') : $t('order.No') }}</div>
          <div class="label">{{ $t('order.istopay') }}：{{ order.IsToPay ? $t('order.Yes') : $t('order.No') }}</div>
          <div class="label"></div>
        </div>
      </div>
    </div>
    <div class="total-fee-box">
      <div class="title">{{ $t('order.totalfee') }}</div>
      <div class="total-fee-table">
        <div class="total-fee-item">
          <div>{{ $t('order.freight') }}（RMB）</div>
          <div class="value">{{ order.Freight }}</div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.storagecharges') }}（RMB）</div>
          <div class="value">{{ order.StorageCharges }}</div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.collectioncommission') }}</div>
          <div class="value">{{ order.CollectionCommission }}</div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.insuredcommission') }}</div>
          <div class="value">{{ order.InsuredCommission }}</div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.weightcharges') }}</div>
          <div class="value">{{ order.WeightCharges }}</div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.lengthcharges') }}</div>
          <div class="value">{{ order.LengthCharges }}</div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.addresssurcharge') }}</div>
          <div class="value">{{ order.AddressSurcharge }}</div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.addedservicefee') }}</div>
          <div class="value">{{ order.AddedServiceFee }}</div>
        </div>
        <div class="total-fee-item btn">
          <div class="label">{{ $t('order.totalfee') }}：{{ order.Receivables }}</div>
          <div class="label">{{ $t('order.discount') }}：{{ `[${order.Discount}]折` }}</div>
          <div class="label">{{ $t('order.accruedcharge', [order.CurrencyName]) }}：<span style="color: #C71414; font-size: 2rem; font-weight: 600">{{ order.DestinationCharges }}</span></div>
        </div>
        <div class="total-fee-item btn">
          <div>{{ $t('order.paytype') }}：<span style="color: #2A82E4; font-size: 1.8rem; font-weight: 600">{{ order.PayTypeName }}</span></div>
          <div>{{ $t('order.paystate') }}：<span style="color: #2A82E4; font-size: 1.8rem; font-weight: 600">{{ order.PayState ? $t('order.paid') : $t('order.nonpayment') }}</span></div>
        </div>
      </div>
    </div>
    <div class="time-box noprint">
      {{ $t('order.createtime') }}：{{ order.CreateTime | formatDate }}
    </div>
    <div class="print-box noprint">
      <el-button type="primary" v-print="print" icon="el-icon-printer">{{ $t('order.printer') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  data() {
    return {
      expands: {},
      print: {
        id: 'printArea',
        popTitle: this.$t('order.title'), // 打印配置页上方标题
        extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
        preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
        previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
        previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
        zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）
        previewBeforeOpenCallback() {}, //预览窗口打开之前的callback（开启预览模式调用）
        previewOpenCallback() {}, // 预览窗口打开之后的callback（开启预览模式调用）
        beforeOpenCallback() {}, // 开启打印前的回调事件
        openCallback() {}, // 调用打印之后的回调事件
        closeCallback() {}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
        url: '',
        standard: '',
        extraCss: '',
      }
    }
  },
  watch: {
    order: {
      handler(newVal) {
        if (newVal.ParcelDetailsInfos && newVal.ParcelDetailsInfos.length) {
          this.expands = {}
          newVal.ParcelDetailsInfos.forEach(item => {
            this.$set(this.expands, item.MainCode, false)
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    expandItem(MainCode) {
      this.$set(this.expands, MainCode, !this.expands[MainCode])
    },
    toDownload(uri) {
      window.open(uri, '_blank');
    }
  },
  computed: {
    totalCount() {
      let count = 0;
      let weight = 0;
      let surcharge = 0;
      this.order.ParcelDetailsInfos.forEach(item => {
        count += 1
        weight += item.Weight
        surcharge += item.Surcharge
      })
      return {
        count, weight, surcharge
      }
    }
  }
}

</script>

<style scoped lang="scss">
@import './print.css';
//::v-deep .el-carousel__container {
//  width: 25rem;
//  height: auto !important;
//}
@media print {
  .noprint {
    display: none;
  }
}
.download {
  color: blue; /* 默认颜色 */
  text-decoration: underline; /* 下划线 */
  cursor: pointer;
}
.btn {
  border-top: none !important;
}
.brn {
  border-right: none !important;
}
.title {
  padding: 10px 0;
  color: #000;
  font-size: 1.8rem;
}
.table {
  width: 100%;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      padding: 1rem 2rem;
      font-size: 1.2rem;
      border: 1px solid #CECECE;
    }
  }
}
.container {
  padding: 2rem 4rem;
  .package-box {
    .package {
      border: 1px solid #CECECE;
      padding: 1rem;
      width: 100%;
      .package-item {
        margin-bottom: 1rem;
        .package-img {
          width: 15rem;
          margin-right: 1rem;
        }
      }
    }
    .total-box {
      padding: 1rem 2rem;
      .total-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0;
        .total-label {
          flex: 1;
          font-size: 1.6rem;
          color: #121212;
        }
      }
    }
  }
  .take-delivery-box {
    .table {
      width: 100%;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #CECECE;
        .label {
          flex: 1;
          padding: 1rem 2rem;
          font-size: 1.4rem;
          border: none;
        }
      }
    }
  }
  .declaration-box {
    .table {
      width: 100%;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #CECECE;
        .label {
          flex: 1;
          padding: 1rem 2rem;
          font-size: 1.4rem;
          border: none;
        }
      }
    }
  }
  .fee-box {
    .table {
      width: 100%;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #CECECE;
        .label {
          flex: 1;
          padding: 1rem 2rem;
          font-size: 1.4rem;
          border: none;
        }
      }
    }
  }
  .time-box {
    width: 100%;
    text-align: right;
    padding: 2rem;
    font-size: 1.6rem;
    color: #000;
    font-weight: 600;
  }
  .print-box {
    width: 100%;
    text-align: center;
    padding: 2rem;
  }
  .total-fee-box {
    .total-fee-table {
      .total-fee-item {
        padding: 1.5rem;
        border: 1px solid #CECECE;
        font-size: 1.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .value {
          color: #A80000;
        }
      }
    }
  }
}

</style>
