<template>
  <el-drawer
      :title="title"
      :visible.sync="drawer"
      :direction="direction"
      append-to-body
      :before-close="handleClose">
    <div class="query-boxes">
      <div class="query-items" v-for="item in listData" :key="item.field">
        <div class="title">{{ item.title }}</div>
        <div class="query-options">
          <div @click="onNav(item.field, nav.value)" :class="{ 'btn_active' : query[item.field] === nav.value }" class="option" v-for="nav in item.options" :key="nav.value">{{ nav.label }}</div>
        </div>
      </div>
      <div class="query-items" v-if="timeOptions && timeOptions.length">
        <div class="time-filter">
          <el-select style="width: 120px;" v-model="query.timeType" size="mini" placeholder="请选择时间类型">
            <el-option
              v-for="item in timeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
              size="mini"
              v-model="query.dateRange"
              value-format="yyyy-MM-dd"
              type="datetimerange"
              :range-separator="$t('order.separator')"
              :start-placeholder="$t('order.startTime')"
              :end-placeholder="$t('order.endTime')">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="query-btn">
      <el-button style="width: 40%" size="small" type="primary" @click="confirm">确定</el-button>
      <el-button style="width: 40%" size="small" type="info" @click="toReset">重置</el-button>
    </div>
  </el-drawer>
</template>

<script>

export default {
  props: {
    direction: {
      type: String,
      default: "rtl"
    },
    title: {
      type: String,
      default: "订单筛选"
    },
    drawer: {
      type: Boolean,
      default: false
    },
    listData: {
      type: Array,
      default: () => []
    },
    initialQuery: {
      type: Object,
      default: () => {}
    },
    timeOptions: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    drawer: {
      handler(val) {
        if (val) {
          this.query = this.initialQuery
          this.$forceUpdate()
        }
      }
    }
  },
  data() {
    return {
      query: {
        dateRange: [],
        timeType: undefined
      }
    }
  },
  mounted() {

  },
  methods: {
    toReset() {
      this.query = {
        dateRange: [],
        timeType: undefined
      }
    },
    confirm() {
      this.$emit("handle-confirm", this.query)
    },
    handleClose() {
      this.$emit('close')
    },
    onNav(field, val) {
      if (this.query[field] === val) {
        this.$set(this.query, field, undefined)
      } else {
        this.$set(this.query, field, val)
      }
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep #el-drawer__title {
  font-size: 16px;
}
.query-btn {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.query-boxes {
  padding: 0 24px;
  margin-bottom: 1.5rem;
  height: calc(100vh - 96px - 32px - 2rem);
  overflow-y: auto;
  .query-items {
    padding: 16px 0;
    border-bottom: 1px solid #ebeef5;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #1f2937;
      margin-bottom: 1rem;
    }
    .query-options {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      .option {
        cursor: pointer;
        font-size: 13px;
        padding: 6px 12px;
        border-radius: 6px;
        background: #f3f4f6;
        color: #4b5563;
        transition: all 0.2s ease;
        border: 1px solid transparent;
        &:hover {
          background: #e5e7eb;
          transform: translateY(-1px);
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }
      }
      .btn_active {
        background: #10b981 !important;
        color: white;
        border-color: #059669;
        box-shadow: 0 2px 4px rgba(16,185,129,0.2);
      }
    }
  }
}

.query-btn {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: 0 -2px 6px rgba(0,0,0,0.05);

  .el-button {
    width: 45%;
    border-radius: 6px;

    &--primary {
      background: #10b981;
      border-color: #059669;

      &:hover {
        background: #059669;
        border-color: #047857;
      }
    }
  }
}

::v-deep .el-drawer__header {
  padding: 16px 24px;
  margin-bottom: 0;
  border-bottom: 1px solid #ebeef5;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #1f2937;
  }
}

::v-deep .el-date-editor {
  width: 100%;
  margin-bottom: 10px;

  .el-range-separator {
    color: #6b7280;
  }

  .el-input__inner {
    border-radius: 6px;
    border-color: #e5e7eb;

    &:hover {
      border-color: #d1d5db;
    }

    &:focus {
      border-color: #10b981;
    }
  }
}

.time-filter {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .el-select {
    width: 100%;
  }
}

::v-deep .el-select {
  .el-input__inner {
    border-radius: 6px;
    border-color: #e5e7eb;

    &:hover {
      border-color: #d1d5db;
    }

    &:focus {
      border-color: #10b981;
    }
  }
}
</style>
