export default {
    data() {
        return {
            PageSizes: [50, 100, 500, 5000, 10000],
            PageIndex: 1,
            PageRows: 50,
            total: 0
        }
    },
    methods: {
        handleSizeChange(val) {
            this.PageRows = val
            this.PageIndex = 1;
            this.getData()
        },
        handleChange(current) {
            this.PageIndex = current;
            this.getData()
        },
    }
}
